<template>
  <div class="wrapper">
    <div v-if="isTimerActive" class="timer">{{ timer }}</div>
    <div
      v-for="i in 32"
      :key="i"
      class="bibi"
      :style="'font-size: ' + randomFontSize() + 'px'"
    >
      {{ bibi }}
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      bibi: "רק ביבי",
      sizes: [10, 20, 30, 50, 100, 300],
      countDown: 0,
      targetDate: new Date("2022-11-01 00:00:00").getTime(),
    };
  },
  created() {
    this.updateTimer();
    this.startTimer();
  },
  methods: {
    randomFontSize() {
      return this.sizes[Math.floor(Math.random() * this.sizes.length)];
    },
    startTimer() {
      if (!this.isTimerActive) {
        return;
      }

      setTimeout(() => {
        this.updateTimer();
        this.startTimer();
      }, 1000);
    },
    updateTimer() {
      this.countDown = this.targetDate - new Date().getTime(); // target date - now
    },
    leadingZero(n) {
      if (n < 10 && n >= 0) {
        return "0" + n;
      } else {
        return n;
      }
    },
  },
  computed: {
    isTimerActive() {
      return this.countDown >= 0;
    },
    timer() {
      const days = Math.floor(this.countDown / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (this.countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (this.countDown % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((this.countDown % (1000 * 60)) / 1000);

      return `${days > 0 ? days : ""} 
      ${this.leadingZero(hours)}:${this.leadingZero(
        minutes
      )}:${this.leadingZero(seconds)}`;
    },
  },
};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.timer {
  padding: 1rem;
  font-size: 5rem;
  text-align: center;
}

.bibi {
  display: inline-block;
}
</style>
